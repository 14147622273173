export interface WebsiteConfig {
  title: string;
  description: string;
  coverImage: string;
  logo: string;
  /**
   * Specifying a valid BCP 47 language helps screen readers announce text properly.
   * See: https://dequeuniversity.com/rules/axe/2.2/valid-lang
   */
  lang: string;
  /**
   * blog full path, no ending slash!
   */
  siteUrl: string;
  /**
   * full url, no username
   */
  facebook?: string;
  /**
   * full url, no username
   */
  twitter?: string;
  linkedin?: string;
  medium?: string;
  github?: string;
  youtube?: string;
  /**
   * hide or show all email subscribe boxes
   */
  showSubscribe: boolean;
  /**
   * create a list on mailchimp and then create an embeddable signup form. this is the form action
   */
  mailchimpAction?: string;
  /**
   * this is the hidden input field name
   */
  mailchimpName?: string;
  /**
   * name and id of the mailchimp email field
   */
  mailchimpEmailFieldName?: string;
  /**
  /**
   * Meta tag for Google Webmaster Tools
   */
  googleSiteVerification?: string;
  /**
  /**
   * Appears alongside the footer, after the credits
   */
  footer?: string;

  disqusname?: string;
  metaDescription?: string;
}

const config: WebsiteConfig = {
  title: 'A Cloudy Affair',
  description: 'A Tech blog about everything Cloud',
  coverImage: 'img/my_blog_logo_2.png',
  logo: 'img/ghost-logo.png',
  lang: 'en',
  siteUrl: 'https://amlanscloud.com',
  // facebook: 'https://www.facebook.com/ghost',
  twitter: 'https://twitter.com/amlana24',
  linkedin: 'https://www.linkedin.com/in/amlan-chakladar-40339525/',
  medium: 'https://medium.com/@amlana21',
  github: 'https://github.com/amlana21',
  youtube: 'https://www.youtube.com/@amlanscloud',
  showSubscribe: false,
  mailchimpAction: 'https://achakladar.us8.list-manage.com/subscribe/post?u=6745adc22c42c6097cdbf7b11&amp;id=381fd8477f',
  mailchimpName: 'b_6745adc22c42c6097cdbf7b11_381fd8477f',
  mailchimpEmailFieldName: 'EMAIL',
  googleSiteVerification: 'GoogleCode',
  // footer: 'is based on Gatsby Casper',
  footer: '',
  disqusname: 'amlans-cloud',
  metaDescription: 'A tech blog about everything Cloud. Here you will find writeups and sample solutions/code about various topics like AWS, Devops related technologies like Docker/CHEF/Jenkins and anything related to coding like Python/Javascript/NodeJS',
};

export default config;
